import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import '../styles/Investidor.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import { IoIosCloudUpload } from 'react-icons/io';
import { setInvestidor } from "../redux/actions";
import Investidores from "../components/Investidores";
import { formatCnpj, formatCpf, formatCurrency, formatFileSize, isValidCPF, isValidEmail } from "../utils/Tools";
import { NumericFormat } from "react-number-format";



export const formatBankAccount = (text) => {
    if (text) {
        const accountNumber = text.replace(/[^0-9a-zA-Z]/g, '').toUpperCase();

        if (accountNumber.length <= 1) {
            return accountNumber;
        }

        const lastCharacter = accountNumber.slice(-1);
        const otherCharacters = accountNumber.slice(0, -1);

        const formattedAccount = otherCharacters.replace(/(.{1,4})(?=(.{3})+(?!.))/g, '$1 ') + '-' + lastCharacter;

        return formattedAccount;
    }
};
export const formatarTelefone = (input) => {
    // Remove todos os caracteres não numéricos do input
    if (input) {
        const numeros = input.replace(/\D/g, '');

        // Aplica a máscara (xx) xxxxx-xxxx enquanto o usuário digita
        let telefoneFormatado = '';
        for (let i = 0; i < numeros.length; i++) {
            if (i === 0) {
                telefoneFormatado = '(';
            } else if (i === 2) {
                telefoneFormatado += ') ';
            } else if (i === 7) {
                telefoneFormatado += '-';
            }
            telefoneFormatado += numeros[i];
        }

        return telefoneFormatado;
    }
};

const Investidor = () => {
    const navigate = useNavigate();
    const data = useSelector((state) => state.dados.dados);
    let razao = data.data?.razaoSocial ?? '';
    let email1 = data.data?.email ?? '';
    const storedData = JSON.parse(localStorage.getItem('notaComercial'));
    const user = JSON.parse(localStorage.getItem('user'));
    const investidor = JSON.parse(localStorage.getItem('investidor'));

    const [available, setAvailable] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [tipoDeEmpresa, setTipoDeEmpresa] = useState('Limitada');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [credorNumero, setCredorNumero] = useState('');
    const [credorRua, setCredorRua] = useState('');
    const [credorCidade, setCredorCidade] = useState('');
    const [credorBairro, setCredorBairro] = useState('');
    const [credorEstado, setCredorEstado] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [cep, setCep] = useState('');
    const [dadosBancarios, setDadosBancarios] = useState('');
    const [conta, setConta] = useState('');
    const [agency, setAgency] = useState('');
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState('');
    const [bankNumber, setBankNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [credorComplemento, setCredorComplemento] = useState('');
    const [tipoDeInstituicao, setTipoDeInstituicao] = useState('FIDC');
    const [nomeCustodiante, setNomeCustodiante] = useState('');
    const [cnpjCustodiante, setCnpjCustodiante] = useState('');
    const [investidores, setInvestidores] = useState(false);
    const [representantes, setRepresentantes] = useState([{ nome: '', email: '', cpf: '', procurador:"0",idFile: "", procuracaoFile: "", procuracaoExpirationDate:"", idDocumentoExpirationDate:"" }]);
    const [atividadePrincipal, setAtividadePrincipal] = useState('');
    const [faturamentoMedioMensal12Meses, setFaturamentoMedioMensal12Meses] = useState('0');

    const [realFaturamento, setRealFaturamento] = useState('');
    const [realFaturamentoExpirationDate, setRealFaturamentoExpirationDate] = useState(''); 
    const inputRefRealFaturamento = useRef(null);

    const [conSocial, setConSocial] = useState('');
    const [conSocialExpirationDate, setConSocialExpirationDate] = useState(''); 
    const inputRefConSocial = useRef(null);

    const [compEndereco, setCompEndereco] = useState('');
    const [compEnderecoExpirationDate, setCompEnderecoExpirationDate] = useState(''); 
    const inputRefCompEndereco = useRef(null);

    const today = new Date().toISOString().split('T')[0];
    const dispatch = useDispatch();

    const toggleInvestidores = () => {
        setInvestidores(!investidores);
    }
    const validateInputsRepresentantes = () =>{
        return representantes.every((rep, index)=>{
        let nome = rep.nome?.trim()??"";
        let email = rep.email?.trim()??"";
        let cpf = rep.cpf?.trim()??"";

        let idDocumento = rep.idDocumento??"";
        let idFile = rep?.idFile?.name??"";
        let idDocumentoExpirationDate = rep?.idDocumentoExpirationDate??"";
        
        let procuracao = rep.procuracao??"";
        let procuracaoFile = rep.procuracaoFile??"";
        let procuracaoExpirationDate = rep?.procuracaoExpirationDate??"";
        let procurador = rep.procurador =="1";

        if((idFile || idDocumento) && !idDocumentoExpirationDate){
            alert(`Expiração do documento de identificação
do representante ${index+1}º esta pendente`);
            return false;
        }
        if((!procuracaoFile || !procuracao) && !procuracaoExpirationDate && procurador){
            alert(`Expiração da procuração do representante ${index+1}º esta pendente`);
            return false;
        }

            if(nome.length<=2){
                alert(`Representante ${index+1}º deve ter um nome maior que dois caracteres`);
                return false;
            }
            if(!isValidEmail(email)){
                alert(`Representante ${index+1}º deve ter um e-mail válido.`);
                return false;
            }
            if(!isValidCPF(cpf)){
                alert(`Representante ${index+1}º deve ter um CPF válido.`);
                return false;
            }
             
            return true;
        })
    }
    const validateInputs = () => {
        
        if (!razaoSocial || razaoSocial === "") {
            alert('Por favor, insira uma Razão Social válida.');
            return false;
        }

        if (!cnpj || cnpj.replace(/\D/g, '').length !== 14) {
            alert('Por favor, insira um CNPJ válido.');
            return false;
        }

        if (!cep || cep.replace(/\D/g, '').length !== 8) {
            alert('Por favor, insira um CEP válido.');
            return false;
        }

        if (!credorRua || credorRua.trim() === "") {
            alert('Por favor, insira uma Rua válida.');
            return false;
        }

        if (!credorNumero) {
            alert('Por favor, insira um Número válido.');
            return false;
        }

        if (!credorBairro) {
            alert('Por favor, insira um Bairro válido.');
            return false;
        }

        if (!credorCidade) {
            alert('Por favor, insira uma Cidade válida.');
            return false;
        }

        if (!credorEstado) {
            alert('Por favor, insira um Estado válido.');
            return false;
        }
        if (!representantes || representantes.length === 0) {
            alert('Por favor, insira um Representante válido.');
            return false;
        }
        
        if(!validateInputsRepresentantes()){
            return false
        }

        if (!telefone) {
            alert('Por favor, insira um Número de Telefone válido.');
            return false;
        }

        if (!selectedBank) {
            alert('Por favor, selecione um Banco.');
            return false;
        }

        if (!bankNumber) {
            alert('Por favor, insira um Número de Banco válido.');
            return false;
        }

        if (!agency) {
            alert('Por favor, insira uma Agência válida.');
            return false;
        }

        if (!conta) {
            alert('Por favor, insira um Número de Conta válido.');
            return false;
        }

        if (!cnpj || cnpj.replace(/\D/g, '').length !== 14) {
            alert('Por favor, insira um CNPJ válido.');
            return false;
        }
        if(tipoDeInstituicao=='FIDC'){
            if (!nomeCustodiante) {
                alert('Por favor, insira um Nome do Custodiante.');
                return false;
            }
    
            if (!cnpjCustodiante || cnpjCustodiante.replace(/\D/g, '').length !== 14) {
                alert('Por favor, insira um CNPJ do Custodiante válido.');
                return false;
            }
        }
        
        const hasRealFaturamento = realFaturamento;
        if(hasRealFaturamento && !realFaturamentoExpirationDate){
            alert('Por favor, insira uma data de expiração do relatório de faturamento.');
            return false;
        }
        
        const hasConSocial = conSocial;
        if(hasConSocial && !conSocialExpirationDate){
            alert('Por favor, insira uma data de expiração do Contrato Social.');
            return false;
        }
        
        const hasCompEndereco = compEndereco;
        if(hasCompEndereco && !compEnderecoExpirationDate){
            alert('Por favor, insira uma data de expiração do Comprovante de Endereço.');
            return false;
        }
        
        return true; // Todos os campos estão válidos
    };

    const handleSearch = async () => {

        try {
            setLoading(true);
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            console.log('====================================');
            console.log(response.data, 'response.data');
            console.log('====================================');
            setCredorComplemento(response.data.complemento);
            setCredorCidade(response.data.localidade);
            setCredorBairro(response.data.bairro);
            setCredorEstado(response.data.uf);
            setCredorRua(response.data.logradouro);
        } catch (error) {
            console.error('Error fetching address:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        // Esta função será executada após cada renderização do componente
        // Role a página para o topo
        window.scrollTo(0, 0);
    }, []);

    const keywordsToPrioritize = ['caixa eco', 'itaú uni', 'bco do brasil', 'bradesco s', 'santander', 'original', 'banco inter', 'bdigital'];

    const sortedBanks = [...banks].sort((a, b) => {
        const bankNameA = a.name ? a.name.toLowerCase() : '';
        const bankNameB = b.name ? b.name.toLowerCase() : '';

        const priorityA = getPriority(bankNameA);
        const priorityB = getPriority(bankNameB);

        if (priorityA !== priorityB) {
            return priorityA - priorityB; // Coloque o banco com prioridade maior primeiro
        } else {
            return bankNameA.localeCompare(bankNameB); // Ordem alfabética padrão
        }
    });

    function getPriority(name) {
        for (let i = 0; i < keywordsToPrioritize.length; i++) {
            if (name.includes(keywordsToPrioritize[i])) {
                return i; // Retorna a posição da primeira correspondência encontrada
            }
        }
        return keywordsToPrioritize.length; // Se não houver correspondências, use a prioridade máxima
    }

    useEffect(() => {
        fetch('https://brasilapi.com.br/api/banks/v1')
            .then(response => response.json())
            .then(data => setBanks(data))
            .catch(error => console.error('Ocorreu um erro ao buscar os bancos:', error));
        setDadosBancarios(`Banco: ${selectedBank}-${bankNumber}/Agencia: ${agency}/Conta: ${conta}`);
    }, [available]);

    const handleBankChange = (event) => {
        const selectedBankName = event.target.value;
        setSelectedBank(selectedBankName);
        const selectedBankData = banks.find(bank => bank.name === selectedBankName);

        if (selectedBankData) {
            setBankNumber(selectedBankData.code);
        }
        else if (selectedBankName === 'BNK Digital') {
            setBankNumber(547);
        } else {
            setBankNumber('');
        }
    };

    const handleBankNumberChange = (event) => {
        const bankNumber = event.target.value;
        setBankNumber(bankNumber);

        const selectedBankData = banks.find(bank => bank.code == bankNumber);

        console.log("bankNumber:", bankNumber);
        console.log("selectedBankData:", selectedBankData);
        console.log("bankNumber:", bankNumber);
        console.log("Banks:", banks);


        if (selectedBankData) {
            setSelectedBank(selectedBankData.name);
        } else {
            setSelectedBank(''); // Limpar o campo "Dados Bancários" se o número do banco não corresponder a nenhum banco na lista.
        }
    };

    const handleFileChange = (e, fileType) => {
        const selectedFiles = e.target.files;
        const file = selectedFiles[0];

        const inputFile = {
            faturamento: () => {
                setRealFaturamento(file);
            },
            contratos: () => {
                setConSocial(file);
            },
            comprovante: () => {
                setCompEndereco(file);
            },
        };

        const input = inputFile[fileType]?inputFile[fileType]():null;
    };

    const handleRemoverArquivo = (fileType) => {
        const inputFile = {
            faturamento: () => {
                setRealFaturamento('');
                inputRefRealFaturamento.current.value = "";
            },
            contratos: () => {
                setConSocial('');
                inputRefConSocial.current.value = "";
            },
            comprovante: () => {
                setCompEndereco('');
                inputRefCompEndereco.current.value = "";
            },
        };

        const input = inputFile[fileType]?inputFile[fileType]():null;
    };
    /** Manter representantesProcuracaoFileIndex e representantesIdFileIndex
     */
    const handlePdf = async (event) => {
        event.preventDefault();
        if (!validateInputs()) {
            return; // Evitar a navegação se as validações não passarem
        }
        const formData = new FormData();
        formData.append('userRef', user.data.email);
        formData.append('tipoDeEmpresa', tipoDeEmpresa);
        formData.append('razaoSocial', razaoSocial);
        formData.append('cnpj', cnpj);
        formData.append('cep', cep);
        formData.append('rua', credorRua);
        formData.append('bairro', credorBairro);
        formData.append('numero', credorNumero);
        formData.append('complemento', credorComplemento);
        formData.append('cidade', credorCidade);
        formData.append('estado', credorEstado);

        representantes.forEach((representante, index) => {
            let ehProcurador = representante?.procurador ?? "0";
            formData.append(`representantes[${index}][nome]`, representante.nome);
            formData.append(`representantes[${index}][email]`, representante.email);
            formData.append(`representantes[${index}][cpf]`, representante.cpf);
            formData.append(`representantes[${index}][procurador]`, ehProcurador);          
            formData.append(`representantes[${index}][procuracaoExpirationDate]`, representante?.procuracaoExpirationDate??"");
            if(representante.procuracaoFile){
                formData.append(`representantesProcuracaoFile`, representante.procuracaoFile);
                formData.append(`representantesProcuracaoFileIndex`, index);

            }
            formData.append(`representantes[${index}][idDocumentoExpirationDate]`, representante?.idDocumentoExpirationDate??"");
            if(representante.idFile){
                formData.append(`representantesIdFile`, representante.idFile);
                formData.append(`representantesIdFileIndex`, index);
            }
        });
        formData.append('telefone', telefone);
        formData.append('banco', selectedBank);
        formData.append('numeroDoBanco', bankNumber);
        formData.append('conta', conta);
        formData.append('agencia', agency);
        formData.append('tipoDeInstituicao', tipoDeInstituicao);
        formData.append('nomeCustodiante', nomeCustodiante);
        formData.append('cnpjCustodiante', cnpjCustodiante);
        formData.append('atividadePrincipal', atividadePrincipal);
        formData.append('faturamentoMedioMensal12Meses', faturamentoMedioMensal12Meses);
        // Adicione os arquivos
        formData.append('realFaturamentoFile', realFaturamento);
        formData.append('realFaturamentoExpirationDate', realFaturamentoExpirationDate);
        formData.append('conSocialFile', conSocial);
        formData.append('conSocialExpirationDate', conSocialExpirationDate);
        formData.append('compEnderecoFile', compEndereco);
        formData.append('compEnderecoExpirationDate', compEnderecoExpirationDate);

        try {
            // Fazer uma chamada Axios para a rota de criação do investidor
            const headers = {
                'Content-Type': 'multipart/form-data'
            }
           // const response = await axios.post('https://c2easybe-production.up.railway.app/investidor', dados, headers);
           const response = await axios.post(process.env.REACT_APP_BASE_URL+'/investidor/new', formData, headers);

            if (response.status === 201) {
                dispatch(setInvestidor(response.data.investidor));
                localStorage.setItem('investidorPreenchido', true);
                localStorage.setItem('investidor', JSON.stringify(response.data.investidor));
                window.alert('Investidor Cadastrado!');
                navigate('/Emissor');
            } else {
                window.alert('Erro ao cadastrar investidor!');
            }
        } catch (error) {
            console.error('Erro ao criar investidor:', error);
            // Lida com erros de requisição, se necessário
        }
    };

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    useEffect(() => {
        handleExpand()

    }, [])

    const addRepresentante = () => {
        setRepresentantes([...representantes, 
            { 
                nome: '', 
                email: '', 
                cpf:'', 
                procurador:0, 
                idFile: "",
                procuracaoFile: "",
            }
        ]); // Adiciona um novo representante vazio
    };

    const removeRepresentante = (index) => {
        const updatedRepresentantes = [...representantes];
        updatedRepresentantes.splice(index, 1);
        setRepresentantes(updatedRepresentantes);
    };

    const handleRepresentanteChange = (index, field, value) => {
        const updatedRepresentantes = [...representantes];
        updatedRepresentantes[index][field] = value;
        setRepresentantes(updatedRepresentantes);
    };

    const handleRepresentanteFileChange = (index, field, e) => {
        const selectedFiles = e.target.files;
        const file = selectedFiles[0];
        const updatedRepresentantes = [...representantes];
        updatedRepresentantes[index][field] = file;
        setRepresentantes(updatedRepresentantes);
    };

    const handleRepresentanteRemoverArquivo = (index, field) =>{
        const updatedRepresentantes = [...representantes];
        updatedRepresentantes[index][field] = '';
        setRepresentantes(updatedRepresentantes);
    }

    return (
        <body className="invesBody">
            <div className='fixed'>
                <Header selected={'investidor'} />
            </div>
            <div className="ahead">
                <h2 className="formH2" style={{ textAlign: "center", marginLeft: '10rem', paddingLeft: 0 }}>
                    Gerenciar Investidores
                </h2>
                <div className="div2">
                    <h3>Bem Vindo, {user.data.razaoSocial}</h3>
                </div>
            </div>
            <main className='investidorDoc2'>
                {!investidores && <div className="divToggle">
                    <div className='buttonToggle1' onClick={toggleInvestidores}>
                        {"Cadastrar novo investidor"}
                    </div>
                </div>}
                {investidores ?
                    <form className="formDoc">
                        <div className="divTitleDoc">
                            <h1 className="h1Doc2">Dados do investidor</h1>
                        </div>
                        <div className={`divInputsDoc`}>
                                <label htmlFor="" className="labelInputsDoc">
                                        Tipo de empresa:
                                </label>
                                <select
                                    className={`inputsDoc`} style={{ width:"98%"}}
                                    value={tipoDeEmpresa}
                                    onChange={(e) => setTipoDeEmpresa( e.target.value)}
                                >
                                <option value="Limitada">Limitada </option>
                                <option value="SA">SA</option>
                            </select>
                        </div>
                        <div className={`divInputsDoc`}>
                            <label htmlFor="" className="labelInputsDoc">
                                Razão Social:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                placeholder="Insira a Razão Social"
                                value={razaoSocial}
                                onChange={(e) => { setRazaoSocial(e.target.value) }}
                                maxLength={120} />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                CNPJ:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                placeholder="Apenas Números"
                                value={formatCnpj(cnpj)}
                                onChange={(e) => { setCnpj(e.target.value) }}
                                maxLength={18}
                            />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                CEP:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                placeholder="Apenas Números"
                                value={cep}
                                onChange={(e) => { setCep(e.target.value) }}
                                onBlur={handleSearch} />{loading && <p width="10%">Buscando...</p>}
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Rua:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                placeholder="Rua Av."
                                value={credorRua}
                                onChange={(e) => { setCredorRua(e.target.value) }}
                            />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Número:
                            </label>
                            <input
                                type="number"
                                placeholder="Apenas Números"
                                className={`inputsDoc`}
                                value={credorNumero}
                                onChange={(e) => { setCredorNumero(e.target.value) }}
                            />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Complemento:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                placeholder="Complemento"
                                value={credorComplemento}
                                onChange={(e) => { setCredorComplemento(e.target.value) }}
                            />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Bairro:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                placeholder="Bairro"
                                value={credorBairro}
                                onChange={(e) => { setCredorBairro(e.target.value) }}
                            />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Cidade:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                placeholder="Cidade"
                                value={credorCidade}
                                onChange={(e) => { setCredorCidade(e.target.value) }}
                            />
                        </div>

                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Estado:
                            </label>
                            <input
                                type="text"
                                placeholder="Estado"
                                className={`inputsDoc`}
                                value={credorEstado}
                                onChange={(e) => { setCredorEstado(e.target.value) }}
                            />
                        </div>
                        <div className="divTitleDoc">
                            <h1 className="h1Doc1">Representantes</h1>
                        </div>
                        {representantes.map((representante, index) => (

                            <RepresentanteItem 
                            representante ={representante} 
                            index={index} 
                            remove={removeRepresentante} 
                            handleChange = {handleRepresentanteChange}
                            handleFileChange={handleRepresentanteFileChange}
                            handleRemoverArquivo={handleRepresentanteRemoverArquivo}
                            countRepresentante={representantes.length}
                            />
                        ))}
                        <div className="buttonRep1" onClick={addRepresentante}>+ Adicionar Representante</div>

                        <div className="divTitleDoc">
                            <h1 className="h1Doc1">Informações complementares</h1>
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Atividade Principal:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={atividadePrincipal}
                                onChange={(e) => { setAtividadePrincipal(e.target.value) }}
                                maxLength={150} />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Faturamento Medio Mensal 12 Meses:
                            </label>
                            <NumericFormat
                                className={`inputsDoc`}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={0}
                            fixedDecimalScale={true}
                            prefix={'R$ '}
                            allowNegative={false}
                            placeholder={formatCurrency(faturamentoMedioMensal12Meses)}
                            onValueChange={(values) => {
                                if(values.value == '') values.value = '0';
                                console.log(values.value);
                                setFaturamentoMedioMensal12Meses(values.value);
                            }}
                        />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Telefone:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={formatarTelefone(telefone)}
                                onChange={(e) => { setTelefone(e.target.value) }}
                                maxLength={15} />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Dados Bancários
                            </label>
                            <select value={selectedBank} onChange={handleBankChange} className="selectDoc">
                                <option value="" className="optionInputsDoc">Selecione um banco</option>
                                <option value="BNK Digital" className="optionInputsDoc">BNK Digital</option>
                                {sortedBanks.map((bank, index) => (
                                    <option key={index} value={bank.name} className="optionInputsDoc">
                                        {bank.name}
                                    </option>
                                ))}
                            </select>

                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">Numero do Banco</label>
                            <input
                                type='number'
                                name="Número do banco"
                                placeholder="Selecione um banco"
                                className={`inputsDoc`}
                                value={bankNumber}
                                onChange={handleBankNumberChange}
                                maxLength={20}
                            />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Agência:
                            </label>
                            <input
                                placeholder="12345"
                                type="text"
                                className={`inputsDoc`}
                                value={agency}
                                onChange={(e) => { setAgency(e.target.value) }}
                                maxLength={8} />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">Conta</label>
                            <input
                                placeholder="123 456-X"
                                type="text"
                                className={`inputsDoc`}
                                value={formatBankAccount(conta)}
                                onChange={(e) => { setConta(e.target.value) }}
                                maxLength={13} />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Tipo de Instituição:
                            </label>
                            <select
                                className="selectDoc"
                                value={tipoDeInstituicao}
                                onChange={(e) => setTipoDeInstituicao(e.target.value)}
                            >
                                <option value="FIDC">FIDC</option>
                                <option value="Factoring">Factoring</option>
                                <option value="Securitizadora">Securitizadora</option>
                            </select>
                        </div>
                        {tipoDeInstituicao=='FIDC'?
                        <>
                            <div className="divInputsDoc">
                                <label htmlFor="" className="labelInputsDoc">
                                    Nome do Custodiante:
                                </label>
                                <input
                                    placeholder=""
                                    type="text"
                                    className={`inputsDoc`}
                                    value={nomeCustodiante}
                                    onChange={(e) => setNomeCustodiante(e.target.value)}
                                    maxLength={100} />
                            </div>
                            <div className="divInputsDoc">
                                <label htmlFor="" className="labelInputsDoc">
                                    CNPJ do Custodiante:
                                </label>
                                <input
                                    placeholder=""
                                    type="text"
                                    className={`inputsDoc`}
                                    value={formatCnpj(cnpjCustodiante)}
                                    onChange={(e) => setCnpjCustodiante(e.target.value)}
                                    maxLength={18} />
                            </div>
                        </>
                        :""}

                        <div className="divInputsDoc">
                            <label className={"customFileInput2"}>
                                <input type="file" accept=".pdf, .doc, .docx" onChange={(e) => { handleFileChange(e, 'faturamento') }} ref={inputRefRealFaturamento} style={{ display: "none" }}/>
                                <IoIosCloudUpload className="uploadIcon" />
                                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>
                                    Relatório de Faturamento
                                </span>
                            </label>
                        </div>
                        <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                        Expiração do Relatório de Faturamento
                        </label>
                        <input
                            placeholder=""
                            type="date"
                            className={`inputsDoc`}
                            value={realFaturamentoExpirationDate}
                            onChange={(e) => setRealFaturamentoExpirationDate(e.target.value)}
                            min={today}/>
                        </div>
                        <div className="divInputsDoc">
                            <label className={"customFileInput2"}>
                                <input type="file" accept=".pdf, .doc, .docx" onChange={(e) => handleFileChange(e, 'contratos')}  ref={inputRefConSocial} style={{ display: "none" }}/>
                                <IoIosCloudUpload className="uploadIcon" />
                                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>
                                    Contrato Social
                                </span>
                            </label>
                        </div>
                        <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                        Expiração do Contrato Social
                        </label>
                        <input
                            placeholder=""
                            type="date"
                            className={`inputsDoc`}
                            value={conSocialExpirationDate}
                            onChange={(e) => setConSocialExpirationDate(e.target.value)}
                            min={today}/>
                        </div>
                        <div className="divInputsDoc">
                            <label className={"customFileInput2"}>
                                <input type="file" accept=".pdf, .doc, .docx" onChange={(e) => handleFileChange(e, 'comprovante')}  ref={inputRefCompEndereco} style={{ display: "none" }}/>
                                <IoIosCloudUpload className="uploadIcon" />
                                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>
                                    Comprovante de Endereço
                                </span>
                            </label>
                        </div>

                        <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                        Expiração do Comprovante de Endereço    
                        </label>
                        <input
                            placeholder=""
                            type="date"
                            className={`inputsDoc`}
                            value={compEnderecoExpirationDate}
                            onChange={(e) => setCompEnderecoExpirationDate(e.target.value)}
                            min={today}/>
                        </div>
                        <div className="uploadedFilesList" style={{ marginTop: "15px" }}>
                            {realFaturamento ? (
                                <div className="uploadedFileItem">
                                     <span>{'Faturamento'}</span>
                                    <span>{realFaturamento.name}</span>
                                    <span>{formatFileSize(realFaturamento.size)}</span>
                                    <button onClick={() => handleRemoverArquivo('faturamento')} className="button11">Remover</button>
                                </div>
                            ) : (
                                <div>
                                   {/* <span>Nenhum arquivo enviado ainda.</span>*/}
                                </div>
                            )}
                        </div>
                        <div className="uploadedFilesList" style={{ marginTop: "15px" }}>
                            {conSocial ? (
                                <div className="uploadedFileItem">
                                     <span>{'Contrato Social'}</span>
                                    <span>{conSocial.name}</span>
                                    <span>{formatFileSize(conSocial.size)}</span>
                                    <button onClick={() => handleRemoverArquivo('contratos')} className="button11">Remover</button>
                                </div>
                            ) : (
                                <div>
                                   {/* <span>Nenhum arquivo enviado ainda.</span>*/}
                                </div>
                            )}
                        </div>
                        <div className="uploadedFilesList" style={{ marginTop: "15px" }}>
                            {compEndereco ? (
                                <div className="uploadedFileItem">
                                     <span>{'Comprovate de Endereço'}</span>
                                    <span>{compEndereco.name}</span>
                                    <span>{formatFileSize(compEndereco.size)}</span>
                                    <button onClick={() => handleRemoverArquivo('comprovante')} className="button11">Remover</button>
                                </div>
                            ) : (
                                <div>
                                   {/* <span>Nenhum arquivo enviado ainda.</span>*/}
                                </div>
                            )}
                        </div>
                        <span style={{display:"block",width:"100%"}}/>
                        <button className="buttonDoc" onClick={toggleInvestidores}>
                            Cancelar
                        </button>
                        <button className="buttonDoc" onClick={handlePdf} disabled={loading}>
                            {loading ? 'Salvando...' : 'Salvar Informações'}
                        </button>
                    </form>
                    :
                    <Investidores />
                }
            </main>
        </body>
    )
}

export const RepresentanteItem = ({representante, index, remove, handleChange, handleFileChange, handleRemoverArquivo, countRepresentante=0}) =>{
    
    const today = new Date().toISOString().split('T')[0];
    useEffect(()=> {
        if(representante.procurador =='0'){
            handleRemoverArquivo(index,'procuracaoFile');
        }
    },[representante.procurador])
    representante.idDocumento = (representante?.idDocumento == "undefined")?"":representante?.idDocumento;
    representante.procuracao = (representante?.procuracao == "undefined")?"":representante?.procuracao; 
    return(
    <div key={index} className={"divRepresentanteLine"}>
        <div className="divRepresentantes">
            <div className="divRep2">
                    <label htmlFor="" className="labelInputsDoc">
                        Representante {index + 1}:
                    </label>
                    <input
                        type="text"
                        className={`inputsRep`}
                        value={representante.nome}
                        onChange={(e) => handleChange(index, 'nome', e.target.value)}
                        maxLength={60}
                    />
            </div>
            <div className="divRep2">
                    <label htmlFor="" className="labelInputsDoc">
                        E-mail:
                    </label>
                    <input
                        type="text"
                        className={`inputsRep`}
                        value={representante.email}
                        onChange={(e) => handleChange(index, 'email', e.target.value)}
                    />
            </div>
            <div className="divRep2">
                    <label htmlFor="" className="labelInputsDoc">
                        CPF:
                    </label>
                    <input
                        type="text"
                        className={`inputsRep`}
                        maxLength={14}
                        value={formatCpf(representante.cpf)}
                        onChange={(e) => handleChange(index, 'cpf', e.target.value)}
                    />
            </div>
            <div className="divRep2">
                    <label htmlFor="" className="labelInputsDoc">
                        Procurador:
                    </label>
                    <select
                            className="selectRep"
                            value={representante.procurador}
                            onChange={(e) => handleChange(index, 'procurador', e.target.value)}
                        >
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                    </select>
            </div>
            {countRepresentante > 1 && ( // Impede a remoção do primeiro campo
            <button type="button" onClick={() => remove(index)} className="buttonRep">-</button>
            )}
        </div>
        <div className="containerInputDoc">
            {representante.idFile ? (
            <div className="uploadedFilesList" style={{ marginTop: "15px" }}>
                <div className="uploadedFileItem">
                    <span>{'Documento de Identificação'}</span>
                    <span>{representante.idFile.name}</span>
                    <span>{formatFileSize(representante.idFile.size)}</span>
                    <button 
                        type="button"
                        onClick={(e) => handleRemoverArquivo(index,'idFile')} 
                        className="button11"
                    >Remover</button>
                </div>
            </div>
            ) : ("")}
            {representante.procuracaoFile ? (
                <div className="uploadedFilesList" style={{ marginTop: "15px" }}>
                    <div className="uploadedFileItem">
                        <span>{'Procuração'}</span>
                        <span>{representante.procuracaoFile.name}</span>
                        <span>{formatFileSize(representante.procuracaoFile.size)}</span>
                        <button
                            type="button"
                            onClick={(e) => handleRemoverArquivo(index,'procuracaoFile')} 
                            className="button11">Remover</button>
                    </div>
                </div>
            ) : ("")}
        </div>
        <div className="divRepresentantes">
            <label className={"customFileInput2"}>
                <input type="file" accept=".pdf, .doc, .docx, .jpg, .png" onChange={(e) => { handleFileChange(index, 'idFile', e)}} style={{ display: "none" }}/>
                <IoIosCloudUpload className="uploadIcon" />
                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>
                    Documento de Identificação 
                </span>
                    {(representante?.idDocumento)?
                        <span className="badgeFileInput">
                            1
                        </span>
                    :""}
            </label>
            <div className="divRep2">
                    <label htmlFor="" className="labelInputsDoc">
                        Expiração do documento
                    </label>
                    <input
                        type="date"
                        className={`inputsRep`}
                        value={representante.idDocumentoExpirationDate}
                        onChange={(e) => handleChange(index, 'idDocumentoExpirationDate', e.target.value)}
                        min={today}
                    />
            </div>
            {(representante.procurador==="1")?
            <>
                <label className={"customFileInput2"} >
                    <input type="file" accept=".pdf, .doc, .docx, .jpg, .png" onChange={(e) => { handleFileChange(index, 'procuracaoFile', e)}} style={{ display: "none" }}/>
                    <IoIosCloudUpload className="uploadIcon" />
                    <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>
                        Procuração 
                    </span>
                    {(representante?.procuracao)?
                        <span className="badgeFileInput">
                            1
                        </span>
                    :""}
                </label>
                <div className="divRep2">
                    <label htmlFor="" className="labelInputsDoc">
                        Expiração da procuração:
                    </label>
                    <input
                        type="date"
                        className={`inputsRep`}
                        value={representante?.procuracaoExpirationDate}
                        onChange={(e) => handleChange(index, 'procuracaoExpirationDate', e.target.value)}
                        min={today}
                    />
                </div>
            </>

                :''
            }
        </div>
    </div>
    )
}


export default Investidor